import React, { useState } from "react"
import ReactDatePicker from "react-datepicker"
import { Controller, useFormContext } from "react-hook-form"
import InputBox from "./InputBox"
import { format } from "date-fns"
import "react-datepicker/dist/react-datepicker.css"

const DatePicker = ({ name, label }) => {
  const [date, setDate] = useState(new Date())
  const startDate = new Date()

  const formatDate = date => format(date, "M/dd/yyyy")

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <InputBox name={name} label={label} errors={errors}>
      <Controller
        control={control}
        name={name}
        defaultValue={formatDate(new Date())}
        render={({ field }) => (
          <ReactDatePicker
            selected={date}
            onChange={date => {
              setDate(date)
              field.onChange(formatDate(date))
            }}
            dateFormat="M/dd/yyyy"
            minDate={startDate}
          />
        )}
      />
    </InputBox>
  )
}

export default DatePicker
