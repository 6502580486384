/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import useCompanyInfo from "../../hooks/useCompanyInfo"
import { graphql, useStaticQuery } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  const { phone, instagram_link, insta_handle, email, brand_name, address } =
    useCompanyInfo()

  const data = useStaticQuery(graphql`
    query {
      allSite {
        nodes {
          siteMetadata {
            author
            description
            env
            siteUrl
            title
          }
        }
      }
    }
  `)

  const siteMeta = data.allSite.nodes[0].siteMetadata

  const metaTitle = title || siteMeta?.title
  const metaDescription = description || siteMeta?.description
  let { pathname } = useLocation()

  if (pathname == "/home/" || pathname == "/home") pathname = "/"

  let curentUrl = process.env.GATSBY_SITE_URL + pathname

  // check trailing slash
  if (!curentUrl.endsWith("/")) curentUrl = curentUrl + "/"

  // remove page- from url
  if (curentUrl.includes("page-")) {
    curentUrl = curentUrl
      .split("/")
      .filter(path => !path.includes("page-"))
      .join("/")
  }

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1")

  //set lowercase url
  curentUrl = curentUrl.toLowerCase()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: curentUrl,
          rel: "canonical",
        },
      ]}
      title={metaTitle}
      titleTemplate={`%s | ${brand_name}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? image : "",
        },
        {
          property: `og:url`,
          content: curentUrl,
        },
        {
          property: `og:image:secure_url`,
          content: image ? image : "",
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image:width`,
          content: `400`,
        },
        {
          property: `og:image:height`,
          content: `300`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMeta?.author || ``,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: ``,
        },
        {
          name: "google-site-verification",
          content: "ML0SSGLwOo9aeudHILxJ5ASxMDzt9Yezio9VosYXMkA",
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
