import React from "react"
import { useFormContext } from "react-hook-form"

const CheckBox = props => {
  const { register } = useFormContext()

  const { name, label, value } = props
  return (
    <>
      <label htmlFor={label} className="checkbox">
        <span className="checkbox-label">{label}</span>
        <input
          id={label}
          type="checkbox"
          className="input-field input-checkbox"
          value={value}
          {...props}
          {...register(name)}
        />
        <span class="checkmark"></span>
      </label>
    </>
  )
}

export default CheckBox
