import React, { useRef } from "react"
import { formTracking } from "../../common/utils/utils"
import { postFormData, sendMail } from "./api/Api"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import Recaptcha from "./elements/Recaptcha"
import TextAreaInput from "./elements/TextAreaInput"
import TextInput from "./elements/TextInput"
import { listVenueSchema } from "./schemas"
import TermsConditions from "./elements/TermsConditions"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"
import FileInput from "./elements/FileInput"
import _ from "lodash"

const ListVenueForm = () => {
  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current?.executeAsync()

    if (token) {
      let postData = { ...data, ...FORM_CONFIG.listVenue }
      postData.first_name = data.firstName
      postData.name = `${data.firstName} ${data.lastName}`
      postData.extra = data

      let formData = new FormData()

      formData.append("data", JSON.stringify(postData))

      if (!_.isEmpty(postData.attachment)) {
        formData.append(
          "files.attachment",
          postData.attachment[0],
          postData.attachment[0].name
        ) // appending file
        delete postData.attachment
      }

      recaptchaRef.current.reset()
      postFormData(formData).then(async apiRes => {
        if (!_.isEmpty(apiRes?.data?.form?.attachment))
          postData["file_url"] = `${apiRes?.data?.form?.attachment[0].url}`

        await sendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.listVenue.event_tracking,
        form_name: FORM_CONFIG.listVenue.form_name,
      })
    }
  }

  return (
    <motion.div
      className="list-venue-form-wrap"
      variants={contentVariant}
      initial="initial"
      animate={{
        ...contentVariant.animate,
        transition: {
          ...contentVariant.animate.transition,
          delay: 0.3,
        },
      }}
    >
      <Form
        className="base-form"
        validationSchema={listVenueSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <TextInput
          name="firstName"
          placeholder="Enter your first name here"
          label="First Name"
        />
        <TextInput
          name="lastName"
          placeholder="Enter your last name here"
          label="Last Name"
        />
        <TextInput
          name="email"
          placeholder="Enter your email here"
          label="Email"
          type="email"
        />
        <TextInput
          name="telephone"
          placeholder="Enter your phone number here"
          label="Phone Number"
          type="number"
          registerOptions={{ valueAsNumber: true }}
        />
        <FileInput
          name="attachment"
          label="Add Attachment"
          // accept=".pdf,.doc"
        />
        <TextAreaInput
          name="message"
          label="Your Comment"
          placeholder="Share any additional information"
          boxClassName="row-full"
        />
        <button type="submit" className="button row-full">
          Submit
        </button>
        <div className="d-none">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </div>
      </Form>
      <TermsConditions />
    </motion.div>
  )
}

export default ListVenueForm
