import React from "react"
import "./ClientCard.scss"
import ImageModule from "../../modules/ImageModule"
import { motion } from "framer-motion"

const ClientCard = ({ client, i }) => {
  if (!client) return null

  const { name, strapi_id, logo, imagetransforms } = client

  let processedImages = imagetransforms?.logo_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <motion.div
      className="client-card"
      variants={{
        initial: {
          opacity: 0,
          y: 50,
        },
        animate: {
          opacity: 1,
          y: 0,
          transition: {
            delay: i * 0.1,
          },
        },
      }}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true, amount: 0.2 }}
    >
      {/* <img src={logo.url} alt={name} /> */}
      <ImageModule
        ImageSrc={logo}
        title={name}
        altText={name}
        imagetransforms={processedImages}
        renderer="srcSet"
        imagename={"client.logo.image"}
        strapi_id={strapi_id}
      />
    </motion.div>
  )
}

export default ClientCard
