import React from "react"
import ResponseMessage from "./ResponseMessage"
import clsx from "clsx"

const InputBox = props => {
  const { name, label, errors, children, className } = props

  return (
    <div
      className={clsx(
        "input-box",
        "input-box-" + name,
        {
          "input-box--invalid": errors && errors[name],
        },
        className
      )}
    >
      {label && (
        <label htmlFor={name} className="input-label">
          {label}
        </label>
      )}
      {children}
      {errors && errors[name] && (
        <ResponseMessage message={errors[name].message} type="error" />
      )}
    </div>
  )
}

export default InputBox
