import { Link } from "gatsby"
import React from "react"
import { privacyURL, termsURL } from "../../../common/utils/urls"

const TermsConditions = () => {
  return (
    <p className="form-terms-text">
      By clicking Submit, you agree to our{" "}
      <Link to={termsURL}>Terms & Conditions</Link> and{" "}
      <Link to={privacyURL}>Privacy Policy</Link>.
    </p>
  )
}

export default TermsConditions
