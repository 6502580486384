import React from "react"
import "./ClientsFormModule.scss"
import useClients from "../../hooks/dataHooks/useClients"
import ClientCard from "../ClientCard/ClientCard"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const ClientsFormModule = () => {
  const clients = useClients()

  return (
    <motion.div className="clients-form-wrap">
      <motion.h4
        className="title"
        variants={contentVariant}
        initial="initial"
        animate={{
          ...contentVariant.animate,
          transition: {
            ...contentVariant.animate.transition,
            delay: 0.5,
          },
        }}
      >
        clients who trust us
      </motion.h4>
      <motion.div
        className="clients-section"
        variants={contentVariant}
        initial="initial"
        animate={{
          ...contentVariant.animate,
          transition: {
            ...contentVariant.animate.transition,
            delay: 0.5,
          },
        }}
      >
        {clients.slice(0, 6).map((client, i) => (
          <ClientCard client={client} i={i} />
        ))}
      </motion.div>
    </motion.div>
  )
}

export default ClientsFormModule
