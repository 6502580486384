import _ from "lodash"
import { z } from "zod"

const ERROR = {
  name: "Please enter a valid name",
  email: "Please enter a valid email address",
  telephone: "Please enter a valid phone number",
  message: "Please enter a valid message",
  property_address: "Please enter a valid address",
  date: "Please enter a valid date",
  time: "Please enter a valid time",
  string: "Please enter a valid string",
  select: "Please select a valid option",
  number: "Please enter a valid number",
  jobTitle: "Please enter a valid job title",
  companyName: "Please enter a valid company name",
}

const SCHEMA = {
  name: z.string({ message: ERROR.name }).min(1, { message: ERROR.name }),
  email: z.string().email({ message: ERROR.email }),
  telephone: z
    .number({ message: ERROR.telephone, invalid_type_error: ERROR.telephone })
    .int({ message: ERROR.telephone })
    .min(4, { message: ERROR.telephone }),
  message: z.string({ message: ERROR.message }),
  property_address: z
    .string({ message: ERROR.property_address })
    .min(1, { message: ERROR.property_address }),
  date: z.string({ message: ERROR.date }),
  time: z.string({ message: ERROR.time }),
  attachment: z.any().refine(files => {
    // make this field optional
    if (_.isEmpty(files)) return true
    return files?.[0]?.size <= 5000000
  }, `Max file size is 5MB.`),
  nationality: z
    .string({ message: ERROR.name })
    .min(1, { message: ERROR.name }),
  string: z.string({ message: ERROR.string }).min(1, { message: ERROR.string }),
  stringArray: z.array(z.string({ message: ERROR.string })),
  select: z.string({ message: ERROR.select }).min(1, { message: ERROR.select }),
  number: z.number({ message: ERROR.number, invalid_type_error: ERROR.number }),
  jobTitle: z
    .string({ message: ERROR.jobTitle })
    .min(1, { message: ERROR.jobTitle }),
  companyName: z
    .string({ message: ERROR.companyName })
    .min(1, { message: ERROR.companyName }),
  // .refine(
  //   files => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
  //   ".jpg, .jpeg, .png and .webp files are accepted."
  // ),
}

export const enquireSchema = z.object({
  firstName: SCHEMA.name,
  lastName: SCHEMA.name,
  jobTitle: SCHEMA.jobTitle,
  companyName: SCHEMA.companyName,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  venues: SCHEMA.stringArray,
  eventType: SCHEMA.select,
  noOfPeople: SCHEMA.number.gt(0, { message: ERROR.number }),
  date: SCHEMA.date,
  duration: SCHEMA.select,
  message: SCHEMA.message,
  attachment: SCHEMA.attachment,
})

export const listVenueSchema = z.object({
  firstName: SCHEMA.name,
  lastName: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
  attachment: SCHEMA.attachment,
})
