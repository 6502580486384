import React, { useId } from "react"
import { Controller, useFormContext } from "react-hook-form"
import ReactSelect from "react-select"
import InputBox from "./InputBox"

const Select = ({ name, label, defaultValue, options }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const uniqueID = useId()

  return (
    <InputBox name={name} label={label} errors={errors}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, name } }) => (
          <ReactSelect
            instanceId={uniqueID}
            options={options}
            value={options.find(option => option.value === value)}
            onChange={option => onChange(option?.value)}
            name={name}
            className="input-field input-select"
            classNamePrefix="input-select"
          />
        )}
      />
    </InputBox>
  )
}

export default Select
