import { graphql, useStaticQuery } from "gatsby"

const useClients = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiClient(filter: { publish: { eq: true } }) {
        nodes {
          ...clientFragment
        }
      }
    }
  `)

  return data.allStrapiClient.nodes
}

export default useClients
