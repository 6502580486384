import clsx from "clsx"
import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import BannerListing from "../components/BannerListing/BannerListing"
import ClientsFormModule from "../components/ClientsFormModule/ClientsFormModule"
import Layout from "../components/Layout/Layout"
import EnquireForm from "../components/forms/EnquireForm"
import ListVenueForm from "../components/forms/ListVenueForm"
import "../styles/main.scss"
import SEO from "../components/SEO/seo"
import { motion } from "framer-motion"
import { layoutVariant } from "../common/animations"

const FormTemplate = ({ data, ...props }) => {
  const pageData = data?.strapiPage
  console.log(
    "🚀 ~ file: form-template.js:17 ~ FormTemplate ~ pageData:",
    pageData
  )

  if (!pageData) return null

  const modules = pageData?.modules

  const { title, description } = pageData.banner_section
  const meta_title = pageData?.seo?.meta_title
  const meta_description = pageData?.seo?.meta_description

  return (
    <motion.div
      className={clsx("form-template", pageData.page_class)}
      key={"form-template" + pageData.strapi_id}
      variants={layoutVariant}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <SEO title={meta_title} description={meta_description} />
      {/* <Layout> */}
      <BannerListing title={title} description={description} />
      <Container className="form-section">
        {modules?.map(module => (
          <>
            {module.strapi_component === "modules.choose-form" &&
              module.form === "enquire_form" && <EnquireForm />}
            {module.strapi_component === "modules.choose-form" &&
              module.form === "list_a_venue" && <ListVenueForm />}
          </>
        ))}
        <ClientsFormModule />
      </Container>
      {/* </Layout> */}
    </motion.div>
  )
}

export default FormTemplate

export const query = graphql`
  query ($page_id: Int) {
    strapiPage(strapi_id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_CHOOSE_FORM {
          ...chooseFormFragment
        }
      }
    }
  }
`
