import React, { useRef } from "react"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import { postFormData, sendMail } from "./api/Api"
import { formTracking } from "../../common/utils/utils"
import Form from "./elements/Form"
import TextInput from "./elements/TextInput"
import Recaptcha from "./elements/Recaptcha"
import { enquireSchema } from "./schemas"
import CheckBox from "./elements/CheckBox"
import useVenues from "../../hooks/dataHooks/useVenues"
import Select from "./elements/Select"
import DatePicker from "./elements/DatePicker"
import TextAreaInput from "./elements/TextAreaInput"
import TermsConditions from "./elements/TermsConditions"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"
import FileInput from "./elements/FileInput"
import _ from "lodash"

const EnquireForm = () => {
  const recaptchaRef = useRef()
  const venues = useVenues()

  const onSubmit = async data => {
    const token = await recaptchaRef.current?.executeAsync()

    if (token) {
      let postData = { ...data, ...FORM_CONFIG.enquire }

      postData.first_name = data.firstName
      postData.name = `${data.firstName} ${data.lastName}`
      postData.extra = data

      let formData = new FormData()

      formData.append("data", JSON.stringify(postData))

      if (!_.isEmpty(postData.attachment)) {
        formData.append(
          "files.attachment",
          postData.attachment[0],
          postData.attachment[0].name
        ) // appending file
        delete postData.attachment
      }

      recaptchaRef.current.reset()
      postFormData(formData).then(async apiRes => {
        if (!_.isEmpty(apiRes?.data?.form?.attachment))
          postData["file_url"] = `${apiRes?.data?.form?.attachment[0].url}`

        await sendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.enquire.event_tracking,
        form_name: FORM_CONFIG.enquire.form_name,
      })
    }
  }

  const eventTypeOptions = [
    { value: "Product launch", label: "Product launch" },
    { value: "Conference", label: "Conference" },
    { value: "Press day", label: "Press day" },
    { value: "Private party", label: "Private party" },
    { value: "Brand launch", label: "Brand launch" },
    { value: "Christmas party", label: "Christmas party" },
    { value: "Fashion show", label: "Fashion show" },
    { value: "Filming or Photography", label: "Filming or Photography" },
    { value: "Market", label: "Market" },
    { value: "Sample sale", label: "Sample sale" },
    { value: "Company away day", label: "Company away day" },
    { value: "Networking", label: "Networking" },
    { value: "Tasting", label: "Tasting" },
    { value: "Fitness class", label: "Fitness class" },
    { value: "Other", label: "Other" },
  ]

  const durationOptions = [
    { value: "1 day", label: "1 day" },
    { value: "2 days", label: "2 days" },
    { value: "3 days", label: "3 days" },
    { value: "4 days", label: "4 days" },
    { value: "5 days", label: "5 days" },
    { value: "6 days", label: "6 days" },
    { value: "7 days", label: "7 Days" },
    { value: "Other", label: "Other" },
  ]

  return (
    <motion.div
      className="enquire-form-wrap"
      variants={contentVariant}
      initial="initial"
      animate={{
        ...contentVariant.animate,
        transition: {
          ...contentVariant.animate.transition,
          delay: 0.3,
        },
      }}
    >
      <Form
        className="base-form"
        validationSchema={enquireSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <TextInput
          name="firstName"
          placeholder="Enter your first name here"
          label="First Name"
        />
        <TextInput
          name="lastName"
          placeholder="Enter your last name here"
          label="Last Name"
        />
        <TextInput
          name="jobTitle"
          placeholder="Enter your job title here"
          label="Job Title"
        />
        <TextInput
          name="companyName"
          placeholder="Enter your company name here"
          label="Company Name"
        />
        <TextInput
          name="email"
          placeholder="Enter your email here"
          label="Email"
          type="email"
        />
        <TextInput
          name="telephone"
          placeholder="Enter your phone number here"
          label="Phone Number"
          type="number"
          registerOptions={{ valueAsNumber: true }}
        />
        <div className="input-box row-full">
          <p className="input-label">Venue</p>
          <div className="checkbox-section">
            {venues.map(venue => {
              return (
                <CheckBox
                  name={"venues"}
                  label={venue.name.toLowerCase()}
                  value={venue.name.toLowerCase()}
                />
              )
            })}
          </div>
        </div>
        <Select
          label={"Event Type"}
          name={"eventType"}
          options={eventTypeOptions}
          defaultValue={eventTypeOptions[0].value}
        />
        <TextInput
          name="noOfPeople"
          placeholder="320"
          label="Number of People"
          type="number"
          registerOptions={{ valueAsNumber: true }}
        />
        <DatePicker name="date" label="Date" />
        <Select
          label={"Duration"}
          name={"duration"}
          options={durationOptions}
          defaultValue={durationOptions[0].value}
        />
        <FileInput
          name="attachment"
          label="Add Attachment"
          // accept=".pdf,.doc"
        />
        <TextAreaInput
          name="message"
          label="Your Comment"
          placeholder="Share any additional information"
          boxClassName="row-full"
        />
        <button type="submit" className="button row-full">
          Submit Enquiry
        </button>
        <div className="d-none">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </div>
      </Form>
      <TermsConditions />
    </motion.div>
  )
}

export default EnquireForm
