const FORM_CONFIG = {
  enquire: {
    form_name: "Enquiry",
    email_temp_user: "enquire_user",
    email_temp_admin: "enquire_admin",
    email_subject_user: "Contact",
    email_subject_admin: "Enquiry",
    event_tracking: "enquire",
    page_url: "/enquire-venues/",
  },
  listVenue: {
    form_name: "List your venue",
    email_temp_user: "list_venue_user",
    email_temp_admin: "list_venue_admin",
    email_subject_user: "List a venue",
    email_subject_admin: "List a venue",
    event_tracking: "list-a-venue",
    page_url: "/list-your-venue/",
  },
}

export const SUCCESS_MSG =
  "Thank you for contacting us. A member of our team will contact you shortly."

export default FORM_CONFIG
